import Swal from "sweetalert2";

// 아이콘
const icons = {
    success: 'success',
    error: 'error',
    warning: 'warning',
    info: 'info',
    question: 'question',
}

// 토스트 인스턴스
const toastInstance = Swal.mixin({
    toast: true,
    position: 'bottom-end',
    showConfirmButton: false,
    timer: 2000,
    timerProgressBar: true,
});

/** 알림창 클래스 */
class Alert {

    /**
     * 생성자
     */
    constructor() { }

    /**
     * 성공 알림창
     * @param {String} text 
     */
    success(text) {

        return new Promise((resolve, reject) => {

            Swal.fire({
                icon: icons.success,
                title: "성공",
                text,
                allowEscapeKey: false,
                confirmButtonText: '확인',
            }).then(result => {
                
                if (result.isConfirmed) resolve(true);
            });
        });
    }

    /**
     * 오류 알림창
     * @param {String} text 
     */
    error(text) {

        return new Promise((resolve, reject) => {
            
            Swal.fire({
                icon: icons.error,
                title: "오류",
                text,
                confirmButtonText: '확인',
                allowEscapeKey: false,
            }).then(result => {
                    
                if (result.isConfirmed) resolve(true);
            });
        });
    }

    /**
     * 경고 알림창
     * @param {String} text 
     */
    warning(text) {

        return new Promise((resolve, reject) => {
            
            Swal.fire({
                icon: icons.warning,
                title: "경고",
                text,
                confirmButtonText: '확인',
                allowEscapeKey: false,
            }).then(result => {
                    
                if (result.isConfirmed) resolve(true);
            });
        });
    }

    /**
     * 정보 알림창
     * @param {String} text 
     */
    info(text) {

        return new Promise((resolve, reject) => {

            Swal.fire({
                icon: icons.info,
                title: "정보",
                text,
                confirmButtonText: '확인',
                allowEscapeKey: false,
            }).then(result => {
                    
                if (result.isConfirmed) resolve(true);
            });
        });
    }

    /**
     * 기본 알림창
     * @param {String} text 
     */
    default(text) {

        return new Promise((resolve, reject) => {

            Swal.fire({
                title: text,
                confirmButtonText: '확인',
                allowEscapeKey: false,
            }).then(result => {
                    
                if (result.isConfirmed) resolve(true);
            });
        });
    }
}

/** 확인 알림창 클래스 */
class Confirm {

    /**
     * 생성자
     */
    constructor() { }

    /**
     * 질문 알림창
     * @param {String} title 
     * @param {String} text 
     * @returns 
     */
    question(title, text) {

        return new Promise(resolve => {
            
            Swal.fire({
                icon: icons.question,
                title,
                text,
                showCancelButton: true,
                allowOutsideClick: false,
                allowEscapeKey: false,
                confirmButtonText: '예',
                cancelButtonText: '아니오',
            }).then(result => {
                
                if (result.isConfirmed) resolve(true);
            });
        });
    }

    /**
     * 생성 여부를 묻는 알림창
     * @param {String} title 
     * @param {String} text 
     * @returns 
     */
    create(title = "생성 확인", text = "생성 하시겠습니까?") {
        
        return new Promise(resolve => {

            this.question(title, text).then(result => resolve(result));
        });
    }

    /**
     * 등록 여부를 묻는 알림창
     * @param {String} title 
     * @param {String} text 
     * @returns 
     */
    registration(title = "등록 확인", text = "등록 하시겠습니까?") {
        
        return new Promise(resolve => {

            this.question(title, text).then(result => resolve(result));
        });
    }

    /**
     * 수정 여부를 묻는 알림창
     * @param {String} title 
     * @param {String} text 
     * @returns 
     */
    edit(title = "수정 확인", text = "수정 하시겠습니까?") {
        
        return new Promise(resolve => {

            this.question(title, text).then(result => resolve(result));
        });
    }

    /**
     * 저장 여부를 묻는 알림창
     * @param {String} title 
     * @param {String} text 
     * @returns 
     */
    save(title = "저장 확인", text = "저장 하시겠습니까?") {
        
        return new Promise(resolve => {

            this.question(title, text).then(result => resolve(result));
        });
    }

    /**
     * 삭제 여부를 묻는 알림창
     * @param {String} title 
     * @param {String} text 
     * @returns 
     */
    remove(title = "삭제 확인", text = "삭제 하시겠습니까?") {
        
        return new Promise(resolve => {

            this.question(title, text).then(result => resolve(result));
        });
    }

    /**
     * 업로드 여부를 묻는 알림창
     * @param {String} title 
     * @param {String} text 
     * @returns 
     */
    upload(title = "업로드 확인", text = "업로드 하시겠습니까?") {
        
        return new Promise(resolve => {

            this.question(title, text).then(result => resolve(result));
        });
    }

    /**
     * 다운로드 여부를 묻는 알림창
     * @param {String} title 
     * @param {String} text 
     * @returns 
     */
    download(title = "다운로드 확인", text = "다운로드 하시겠습니까?") {
        
        return new Promise(resolve => {

            this.question(title, text).then(result => resolve(result));
        });
    }
}

/** 토스트 메시지 클래스 */
class Toast {

    /**
     * 생성자
     */
    constructor() { }

    /**
     * 등록
     * @param {String} message : 메시지
     */
    add(message = '등록 되었습니다.') {

        toastInstance.fire({
            icon: icons.success,
            title: message,
        });
    }

    /**
     * 저장
     * @param {String} message : 메시지
     */
    save(message = '저장 되었습니다.') {

        toastInstance.fire({
            icon: icons.success,
            title: message,
        });
    }

    /**
     * 삭제
     * @param {String} message : 메시지
     */
    remove(message = '삭제 되었습니다.') {

        toastInstance.fire({
            icon: icons.success,
            title: message,
        });
    }

    /**
     * 파일 다운로드 진행중
     * @param {String} message 
     */
    fileDownloading(message = '파일을 다운로드 중입니다.') {

        toastInstance.fire({
            icon: icons.success,
            title: message,
        });
    }

    /**
     * 파일 다운로드 완료
     * @param {String} message 
     */
    fileDownloaded(message = '파일을 다운로드를 완료했습니다.') {

        toastInstance.fire({
            icon: icons.success,
            title: message,
        });
    }

    /**
     * 사용자 정의
     * @param {String} icon : 아이콘
     * @param {String} message : 메시지
     */
    custom(icon, message) {

        toastInstance.fire({
            icon: icon,
            title: message,
        });
    }
}

// 객체 생성
const alert = new Alert();
const confirm = new Confirm();
const toast = new Toast();

/**
 * alert, confirm 알림창, toast 관련 모듈 (sweetalert2 라이브러리 사용)
 * 자주 사용하는 alert, confirm, toast 정의
 * - 참고 사이트: https://sweetalert2.github.io/
 */
export default {
    alert,
    confirm,
    toast,
}